<template>
  <v-container class="mx-auto my-8">
    <v-card class="elevation-5 user-card">
      <v-card-title class="title">
        <strong class="mx-auto">DADOS DO BENEFICIÁRIO</strong>
        <v-btn icon @click="$emit('fechar')"  class="ml-auto close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="user-info-row">
          <strong>SEQUÊNCIAL: </strong> {{ beneficiario.sequencial }}
        </div>
        <div class="user-info-row">
          <strong>TIPO REGISTRO: </strong> {{ beneficiario.tipoRegistro }}
        </div>
        <div class="user-info-row">
          <strong>PLANO: </strong> {{ beneficiario.plano }}
        </div>
        <div class="user-info-row">
          <strong>CÓDIGO DO BENEFICIÁRIO: </strong> {{ beneficiario.codigoBeneficiario }}
        </div>
        <div class="user-info-row">
          <strong>NOME: </strong> {{ beneficiario.nome }}
        </div>
        <div class="user-info-row">
          <strong>CPF: </strong> {{ beneficiario.cpf }}
        </div>
        <div class="user-info-row">
          <strong>RG: </strong> {{ beneficiario.rg }}
        </div>
        <div class="user-info-row">
          <strong>ORGÃO EXPEDIDOR DO RG: </strong> {{ beneficiario.orgaoExpedidorRG }}
        </div>
        <div class="user-info-row">
          <strong>NOME DA MÃE: </strong> {{ beneficiario.nomeMae }}
        </div>
        <div class="user-info-row">
          <strong>DATA DO NASCIMENTO: </strong> {{ beneficiario.dataNascimento }}
        </div>
        <div class="user-info-row">
          <strong>SEXO: </strong> {{ beneficiario.sexo }}
        </div>
        <div class="user-info-row">
          <strong>CNS: </strong> {{ beneficiario.cns }}
        </div>
        <div class="user-info-row">
          <strong>ESTADO CIVIL: </strong> {{ beneficiario.estadoCivil }}
        </div>
        <div class="user-info-row">
          <strong>LOGRADOURO: </strong> {{ beneficiario.logradouro }}
        </div>
        <div class="user-info-row">
          <strong>NÚMERO DA RESIDÊNCIA: </strong> {{ beneficiario.numeroResidencia }}
        </div>
        <div class="user-info-row">
          <strong>COMPLEMENTO DO ENDEREÇO: </strong> {{ beneficiario.complementoEndereco }}
        </div>
        <div class="user-info-row">
          <strong>BAIRRO: </strong> {{ beneficiario.bairro }}
        </div>
        <div class="user-info-row">
          <strong>CIDADE: </strong> {{ beneficiario.cidade }}
        </div>
        <div class="user-info-row">
          <strong>UF: </strong> {{ beneficiario.uf }}
        </div>
        <div class="user-info-row">
          <strong>CEP: </strong> {{ beneficiario.cep }}
        </div>
        <div class="user-info-row">
          <strong>TIPO DO TELEFONE: </strong> {{ beneficiario.tipoTelefone1 }}
        </div>
        <div class="user-info-row">
          <strong>DDD: </strong> {{ beneficiario.dddTelefone1 }}
        </div>
        <div class="user-info-row">
          <strong>TELEFONE: </strong> {{ beneficiario.telefone1 }}
        </div>
        <div class="user-info-row">
          <strong>RAMAL TELEFONE: </strong> {{ beneficiario.ramalTelefone1 }}
        </div>
        <div class="user-info-row">
          <strong>TIPO DE MOVIMENTAÇÃO: </strong> {{ beneficiario.tipoMovimentacao }}
        </div>
        <div class="user-info-row">
          <strong>DATA INÍCIO DA VIGÊNCIA: </strong> {{ beneficiario.dataInicioVigencia }}
        </div>
        <div class="user-info-row">
          <strong>SITUAÇÃO DO BENEFICIÁRIO: </strong> {{ beneficiario.situacaoBenef }}
        </div>
        <div class="user-info-row">
          <strong>MOTIVO CANCELAMENTO: </strong> {{ beneficiario.motivoCancelamento }}
        </div>
        <div class="user-info-row">
          <strong>EMAIL: </strong> {{ beneficiario.email }}
        </div>
        <div class="user-info-row">
          <strong>GRAU DE PARENTESCO: </strong> {{ beneficiario.grauParentesco }}
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>

export default {
  props: {
    beneficiario: {}
  },
};
</script>

<style>
.title {
  font-size: 70px;
  font-weight: 600;
  background-image: linear-gradient(to left, #b8b0ce, #1f1f1f);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  display: flex;
  justify-content: center;
}

.user-info-row {
  border-bottom: 1px solid #e0e0e0;
  padding: 8px 0;
}

.v-card {
  background-color: #f5f5f5;
}

.close-btn {
  background-color: #ad030093; /* Cor de fundo vermelha */
  border-radius: 50%; /* Deixa o botão com bordas arredondadas */
  padding: 5px; /* Espaçamento interno do botão */
}

</style>
