<template>
  <v-container fluid>
    <v-overlay v-if="isLoadingData">
      <v-progress-circular
        :size="100"
        :width="5"
        color="purple"
        indeterminate
        v-if="isLoadingData"
      ></v-progress-circular>
    </v-overlay>

    <v-card min-height="300px">
      <v-card-title class="title"> BUSCA DE BENEFICIÁRIO </v-card-title>

      <v-container fluid fill-height>
        <v-row align="center">
          <v-col cols="12" md="5">
            <v-text-field
              v-model="cpf"
              label="Pesquisar por CPF"
              @keydown.enter="buscarPorCpf"
              class="pr-4 custom-input"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              v-model="matricula"
              label="Pesquisar por matrícula"
              type="number"
              @keydown.enter="buscarPorMatricula"
              class="pr-4 custom-input"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn color="success" dark small @click="buscar">
              Buscar
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row> </v-row>

        <dadosBeneficiario
          v-show="existeBeneficiario"
          @fechar="fecharDadosDoBeneficiario"
          :beneficiario="this.beneficiario"
        />

        <v-sheet class="d-flex flex-column">
          <v-snackbar :timeout="2000" v-model="snackbar" :color="corSnackbar">
            <span class="center">{{ mensagem }}</span>
          </v-snackbar>
        </v-sheet>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import dadosBeneficiario from "../beneficiario/dadosBeneficiario.vue";
import BuscaBeneficiario from "../../apis/beneficiario/BuscaBeneficiarioApi";
import Beneficiario from "../../entities/beneficiario";

export default {
  components: {
    dadosBeneficiario,
  },
  data() {
    return {
      cpf: "",
      matricula: "",
      beneficiario: null,
      existeBeneficiario: false,
      snackbar: false,
      corSnackbar: "",
      mensagem: "",
      isLoadingData: false,
    };
  },
  created() {
    this.beneficiario = new Beneficiario();
  },
  methods: {
    cpfValido(cpf) {
      const regex = /^(?:\d{3}\.\d{3}\.\d{3}-\d{2}|\d{11})$/;
      return regex.test(cpf);
    },

    matriculaValida(matricula) {
      const regex = /^[0-9]+$/;
      return (
        regex.test(matricula) &&
        (matricula.length === 11 || matricula.length === 16)
      );
    },

    mostrarAviso(cor, msg) {
      this.snackbar = true;
      this.corSnackbar = cor;
      this.mensagem = msg;
    },
    validarSePreenchido(validacao, mensagem, ...inputs) {
      if (inputs?.length > 0) {
        for (const input of inputs) {
          if (input) {
            if (validacao(input)) {
              this.mostrarAviso("error", mensagem);
              return false;
            }
          }
        }
      } else if (validacao()) {
        this.mostrarAviso("error", mensagem);
        return false;
      }
      return true;
    },
    async buscar() {
      if (
        !this.validarSePreenchido(
          () => !this.cpf && !this.matricula,
          "Informe CPF ou matrícula"
        )
      )
        return;

      if (
        !this.validarSePreenchido(
          (cpf) => !this.cpfValido(cpf),
          "CPF inválido",
          this.cpf
        )
      )
        return;

      if (
        !this.validarSePreenchido(
          (matricula) => !this.matriculaValida(matricula),
          "Matrícula precisa conter 11 ou 16 dígitos!",
          this.matricula
        )
      )
        return;

      this.isLoadingData = true;

      const cpfSemMascara = this.cpf.replace(/[^\d]/g, "");

      try {
        await BuscaBeneficiario.buscarPor({
          cpf: cpfSemMascara,
          matricula: this.matricula,
        })
          .then((resposta) => {
            if (resposta.data.SEQUENCIAL === undefined) {
              this.mostrarAviso("warning", `Beneficiário não encontrado!`);
              this.existeBeneficiario = false;
            } else {
              this.beneficiario = Beneficiario.criar(resposta.data);
              this.mostrarAviso("success", `Beneficiário encontrado!`);
              this.existeBeneficiario = true;
            }
          })
          .finally(() => {
            this.isLoadingData = false;
          });
      } catch (error) {
        const mensagem =
          error.data?.message ||
          "Ocorreu um erro inesperado, entre em contato com a cassems";
        this.isLoadingData = false;
        this.mostrarAviso("error", mensagem);
      }
    },

    async buscarPorMatricula() {
      this.isLoadingData = true;

      try {
        await BuscaBeneficiario.buscarPorMatricula(this.matricula)
          .then((resposta) => {
            if (resposta.data.SEQUENCIAL === undefined) {
              this.mostrarAviso("warning", `Beneficiário não encontrado!`);
              this.existeBeneficiario = false;
            } else {
              this.beneficiario = Beneficiario.criar(resposta.data);
              this.mostrarAviso("success", `Beneficiário encontrado!`);
              this.existeBeneficiario = true;
            }
          })
          .finally(() => {
            this.isLoadingData = false;
          });
      } catch (error) {
        const mensagem =
          error.data?.message ||
          "Ocorreu um erro inesperado, entre em contato com a cassems";
        this.isLoadingData = false;
        this.mostrarAviso("error", mensagem);
      }
    },
    fecharDadosDoBeneficiario() {
      this.beneficiario = new Beneficiario();
      this.existeBeneficiario = false;
    },
  },
};
</script>

<style>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-card {
  min-width: 400px;
  padding: 24px;
}
.title {
  font-size: 70px;
  font-weight: 600;
  background-image: linear-gradient(to left, #a0a0a0, #000000);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  display: flex;
  justify-content: center;
}

.custom-input {
  width: 100%;
}

.custom-btn {
  margin-top: -10px;
  width: 95%;
}

.search-btns {
  display: flex;
  justify-content: center;
}

.search-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
