export default class Beneficiario {
  constructor(
    sequencial,
    tipoRegistro,
    plano,
    codigoBeneficiario,
    nome,
    cpf,
    rg,
    orgaoExpedidorRG,
    nomeMae,
    dataNascimento,
    sexo,
    cns,
    estadoCivil,
    logradouro,
    numeroResidencia,
    complementoEndereco,
    bairro,
    cidade,
    uf,
    cep,
    tipoTelefone1,
    dddTelefone1,
    telefone1,
    ramalTelefone1,
    tipoMovimentacao,
    dataInicioVigencia,
    situacaoBenef,
    motivoCancelamento,
    email,
    grauParentesco,
    cpfResponsavel
  ) {
    this.sequencial = sequencial;
    this.tipoRegistro = tipoRegistro;
    this.plano = plano;
    this.codigoBeneficiario = codigoBeneficiario;
    this.nome = nome;
    this.cpf = cpf;
    this.rg = rg;
    this.orgaoExpedidorRG = orgaoExpedidorRG;
    this.nomeMae = nomeMae;
    this.dataNascimento = dataNascimento;
    this.sexo = sexo;
    this.cns = cns;
    this.estadoCivil = estadoCivil;
    this.logradouro = logradouro;
    this.numeroResidencia = numeroResidencia;
    this.complementoEndereco = complementoEndereco;
    this.bairro = bairro;
    this.cidade = cidade;
    this.uf = uf;
    this.cep = cep;
    this.tipoTelefone1 = tipoTelefone1;
    this.dddTelefone1 = dddTelefone1;
    this.telefone1 = telefone1;
    this.ramalTelefone1 = ramalTelefone1;
    this.tipoMovimentacao = tipoMovimentacao;
    this.dataInicioVigencia = dataInicioVigencia;
    this.situacaoBenef = situacaoBenef;
    this.motivoCancelamento = motivoCancelamento;
    this.email = email;
    this.grauParentesco = grauParentesco;
    this.cpfResponsavel = cpfResponsavel;
  }

  static criar(data) {
    return new Beneficiario(
      data?.SEQUENCIAL || null,
      data?.TIPOREGISTRO || null,
      data?.PLANO || null,
      data?.CODIGODOBENEFICIARIO || null,
      data?.NOME || null,
      data?.CPF || null,
      data?.RG || null,
      data?.ORGAOEXPRG || null,
      data?.NOMEMAE || null,
      data?.DATANASCIMENTO ? new Date(data.DATANASCIMENTO).toLocaleDateString('pt-br') :null,
      data?.SEXO || null,
      data?.CNS || null,
      data?.ESTADOCIVIL || null,
      data?.LOGRADOURO || null,
      data?.NUMRESID || null,
      data?.COMPLENDERECO || null,
      data?.BAIRRO || null,
      data?.CIDADE || null,
      data?.UF || null,
      data?.CEP || null,
      data?.TIPOTELEFONE1 || null,
      data?.DDDTELEFONE1 || null,
      data?.TELEFONE1 || null,
      data?.RAMALTELEFONE1 || null,
      data?.TIPOMOVIMENTACAO || null,
      data?.DATAINIVIGE ? new Date(data.DATAINIVIGE).toLocaleDateString('pt-br') :null,
      data?.SITUACAOBENEF || null,
      data?.MOTCANCELAMENTO || null,
      data?.EMAIL || null,
      data?.GRAPARENT || null,
      data?.CPF_1 || null
    );
  }

  static criarBeneficiarioParaTeste() {
    return new Beneficiario(
      1143311,
      "A",
      "436652012",
      "01295659000",
      "LUCIMARA MARTINES DE MELO",
      "80376410159",
      "930029",
      "SSP",
      "LUCIA MARTINES DE MELO",
      "1977-09-08",
      2,
      "898003006759194",
      1,
      "R CACHOEIRINHA",
      113,
      null,
      "Residencial Cedrinho",
      "CAMPO GRANDE",
      "MS",
      "79074-815",
      "1",
      "67",
      "6799552576",
      null,
      1,
      "2012-05-09",
      1,
      null,
      "lucimaramelo@gmail.com",
      0,
      "80376410159"
    );
  }

}
